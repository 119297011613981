import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
	AuthenticationGuard,
	OauthCallbackComponent,
	OauthTokenGuard,
} from '@wame/ngx-adfs';
export const userGroupRoutes: Routes = [
	// {
	// 	path: 'oauth',
	// 	component: OauthCallbackComponent,
	// 	canActivate: [OauthTokenGuard],
	// },
	// {
	// 	path: '',
	// 	canActivate: [AuthenticationGuard],
	// },
];

@NgModule({
	imports: [RouterModule.forRoot(userGroupRoutes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
