/**This file contains a constant object that has properties that are necessary
 * for the build found in the `local` configuration in `angular.json`.
 * The contents of this file will be overwritten for other configurations.
 */

export const environment = {
	production: false,
	ectsAppUrl: 'http://localhost:9500',
	dealerBaseUrl: 'https://ects-cloud-dealer-acbbgt7rva-ey.a.run.app/',
	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2',
	openidClientId: 'urn:ects_ui:clientid:web_ects:dev',
	openidResource: 'urn:ects_ui:resource:web_ects:dev',
	openidRedirectUrl: 'https://ects-cloud-ui-acbbgt7rva-ey.a.run.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
