import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { NgxFrfUtilitiesModule } from '@wame/ngx-frf-utilities';
import {
	NgxAdfsModule,
	HttpRequestInterceptor,
	UserIdService,
} from '@wame/ngx-adfs';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule,
		AppRoutingModule,
		HttpClientModule,
		NgxFrfUtilitiesModule,
		NgxAdfsModule.forRoot({
			openidUrl: environment.openidUrl,
			openidClientId: environment.openidClientId,
			openidResource: environment.openidResource,
		}),
	],
	providers: [
		UserIdService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
